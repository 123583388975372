<template>
  <div class="about">
    <v-app-bar dark fixed>
      <v-btn icon class="hidden-xs-only">
        <v-icon @click="$router.push('/')">mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        <strong>{{ this.$route.params.id }}</strong>
        &nbsp;
        <em>{{ $t('queue.queue') }}</em>
      </v-toolbar-title>

      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('generic.search')"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer />
      <v-btn
        class="black--text"
        :outlined="!showOnlyUsersRequests"
        small
        color="secondary"
        @click="handleMyRequests"
      >{{ $t('queue.myRequests') }}</v-btn>
      <v-spacer />
      <v-btn
        small
        :outlined="showOnlyUsersRequests"
        color="primary"
        @click="handleAllRequests"
      >{{ $t('queue.allRequests') }}</v-btn>
      <v-spacer />
      <v-btn
        v-if="showOnlyUsersRequests"
        small
        color="error"
        @click="handlePauseAllClick"
      >{{ $t('action.pauseAll') }}</v-btn>
      <v-spacer />
      <span>
        <v-icon>mdi-account-outline</v-icon>
        {{ isAuthenticated.profile.given_name }} {{ isAuthenticated.profile.family_name }}
      </span>
      <v-btn icon color="gray" @click="handleLogout">
        <v-icon>mdi-export</v-icon>
      </v-btn>
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title
            class="headline grey lighten-2"
            primary-title
          >{{ $t('queue.pauseAllRequests') }}</v-card-title>
          <v-container>
            <v-row>
              <v-col sm="12">
                <v-text-field v-model="reason" :label="$t('request.reasonForPause')"></v-text-field>
              </v-col>
              <v-col sm="12">
                <v-chip v-for="(r, index) in pauseReasons" :key="index" @click="reason = r">{{ r }}</v-chip>
              </v-col>
            </v-row>
          </v-container>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="dialog = false">{{ $t('generic.cancel') }}</v-btn>
            <v-btn color="error" @click="handlePauseAllRequests()">{{ $t('action.pause') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <v-sheet class="overflow-y-auto pt-15">
      <v-data-table
        :headers="headers"
        :items="requests"
        :items-per-page="-1"
        must-sort
        sort-by="time"
        group-by="latestStatus.status"
        group-desc
        :no-data-text="$t('queue.noRequests')"
        :search="search"
        class="elevation-1"
        :footer-props="{
          disablePagination:true,
          disableItemsPerPage:true
        }"
        @click:row="handleRowClick"
      >
        <template #item.status="{ item }">
          <v-row>
            <v-col md="5">
              <v-chip
                :color="getStatusProperties(item).color"
                dark
              >{{ $t(getStatusProperties(item).name) }}</v-chip>
            </v-col>
            <v-col v-if="item.latestStatus" md="7">
              <v-row>
                <v-chip
                  v-if="item.latestStatus.userName && item.latestStatus.userName.length"
                  color="primary"
                  dark
                  x-small
                >
                  <v-avatar left>
                    <v-icon x-small>mdi-account</v-icon>
                  </v-avatar>
                  {{ item.latestStatus.userFriendlyName }}
                </v-chip>
              </v-row>
              <v-row>
                <v-chip v-if="item.latestStatus.comment" color="gray" dark x-small>
                  <v-avatar left>
                    <v-icon x-small>mdi-comment-alert</v-icon>
                  </v-avatar>
                  {{ item.latestStatus.comment }}
                </v-chip>
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template
          #item.contentDesc="{ item }"
        >{{ item.quantity }} {{ item.storageUnitTypeDesc }} {{ item.contentDesc }}</template>
        <template #item.priority="{ item }">
          <v-icon
            v-if="item.type"
            :color="requestTypes[item.type].color"
          >{{ requestTypes[item.type].icon }}</v-icon>
        </template>
        <template #item.info="{ item }">
          <v-tooltip v-if="item.comment" top>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" color="blue" v-on="on">mdi-information</v-icon>
            </template>
            <span>{{ item.comment }}</span>
          </v-tooltip>
        </template>
        <template #item.time="{ item }">{{ item.time === '23:59' ? ' ' : item.time }}</template>
        <template #group.header="{ group, toggle }">
          <tr class="v-row-group__header">
            <td colspan="8" class="text-start">
              <button
                type="button"
                class="ma-0 v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--small"
              >
                <span class="v-btn__content" @click="toggle">
                  <i aria-hidden="true" class="v-icon notranslate mdi mdi-minus theme--light"></i>
                </span>
              </button>
              {{ $t(statuses[statusMap[group]].name) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-sheet>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { statuses, statusMap, requestTypes } from '@/helpers'

export default {
  name: 'DisplayQueue',
  data () {
    return {
      search: '',
      reason: '',
      statuses,
      statusMap,
      showOnlyUsersRequests: false,
      selectedRequest: null,
      dialog: false,
      scanner: false,
      requestTypes,
      pauseReasons: ['4Å', 'Pallsök', 'Trailer', 'Utsida', 'Levplats', 'Asea varm', 'Dragarvagn', 'Fordonshall'],
      headers: [
        { text: this.$t('queue.table.id'), value: 'sapId' },
        { text: this.$t('queue.table.from'), value: 'fromBin' },
        { text: this.$t('queue.table.to'), value: 'destBin' },
        { text: this.$t('queue.table.request'), value: 'contentDesc' },
        { text: this.$t('queue.table.type'), value: 'priority' },
        { text: this.$t('queue.table.info'), value: 'info' },
        { text: this.$t('queue.table.time'), value: 'time' },
        { text: this.$t('queue.table.status'), value: 'status' }
      ]
    }
  },
  computed: {
    ...mapGetters(['queues']),
    selectedQueue () {
      return this.queues[this.$route.params.id]
    },
    userName () {
      return this.isAuthenticated.userName.split('@')[0]
    },
    requests () {
      if (this.selectedQueue) {
        return !this.showOnlyUsersRequests
          ? this.selectedQueue.requests
          : this.selectedQueue.requests.filter((tr) => {
            const currentStatus = tr.latestStatus
            return currentStatus ? currentStatus.userName === this.userName : false
          })
      } else return []
    }
  },
  created () { },
  methods: {
    ...mapActions(['postStatus']),

    handleLogout () {
      this.$adal.logout()
    },
    handleMyRequests () {
      this.showOnlyUsersRequests = true
    },
    handleAllRequests () {
      this.showOnlyUsersRequests = false
    },
    handleStatusClick (tr) {
      const newStatus = {
        userName: this.userName
      }
      switch (tr.statusHistory[tr.statusHistory.length - 1].status) {
        case statuses.COMPLETED.code:
          return
        case statuses.RESERVED.code:
          this.selectedRequest = tr
          this.dialog = true
          return
        default:
          newStatus.status = statuses.RESERVED.code
          break
      }
      this.postStatus({ requestId: tr.id, status: newStatus })
    },
    getStatusProperties (tr) {
      const currentStatus = tr.latestStatus
      return currentStatus ? statuses[statusMap[currentStatus.status]] : {}
    },
    handleRowClick (row) {
      this.$router.push(`/request/${row.id}`)
    },
    handleSearchClick (coto) {
      this.$router.push(`/request/${coto.id}`)
    },
    handlePauseAllClick () {
      this.dialog = true
    },
    handlePauseAllRequests () {
      const newStatus = {
        comment: this.reason,
        status: statuses.PAUSED.code
      }

      this.requests.forEach(r => {
        this.postStatus({ requestId: r.id, status: newStatus })
      })

      this.selectedRequest = null
      this.reason = ''
      this.dialog = false
    }
  }
}
</script>
